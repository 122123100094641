<template>
<div class="page">
  <el-page-header @back="goBack" content="评估报告"></el-page-header>
  <div class="report" ref="codeRef">
    <div class="wrap">
      <h1>托育机构质量评估标准报告</h1>
      <p class="sub-title">Evaluation standards for quality of chlidcare institutions</p>
      <el-descriptions :column="1">
        <el-descriptions-item label="机构名称">{{inspect_info.organization_name}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="2" v-if="inspect_info.type == 1" class="des-two">
        <el-descriptions-item label="报告编号">{{inspect_info.serial}}</el-descriptions-item>
        <el-descriptions-item label="提交时间">{{inspect_info.submit_date}}</el-descriptions-item>
        <el-descriptions-item label="自评用时">{{inspect_info.total_time}}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{inspect_info.examine_date}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" v-if="inspect_info.type != 1">
        <el-descriptions-item label="报告编号">{{inspect_info.serial}}</el-descriptions-item>
        <el-descriptions-item label="审核用时">{{inspect_info.total_time}}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{inspect_info.examine_date}}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="part">
        <div class="title">评估等级：
          <template v-if="inspect_info.type != 1">
            <span v-if="inspect_info.results == 1" style="color:#F04134">需要重大改进</span>
            <span v-if="inspect_info.results == 2" style="color:#FFBA01">接近国家标准</span>
            <span v-if="inspect_info.results == 3" style="color:#1ABE6B">符合国家标准</span>
            <span v-if="inspect_info.results == 4" style="color:#0079FE">超过国家标准</span>
          </template>
          <template v-if="inspect_info.type == 1">
            <span v-if="inspect_info.examine_results == 1" style="color:#F04134">需要重大改进</span>
            <span v-if="inspect_info.examine_results == 2" style="color:#FFBA01">接近国家标准</span>
            <span v-if="inspect_info.examine_results == 3" style="color:#1ABE6B">符合国家标准</span>
            <span v-if="inspect_info.examine_results == 4" style="color:#0079FE">超过国家标准</span>
          </template>
        </div>
        <el-descriptions :column="1">
          <el-descriptions-item label="评审总分" v-if="inspect_info.type == 1">{{inspect_info.examine_score * 1}}分</el-descriptions-item>
          <el-descriptions-item label="评审总分" v-if="inspect_info.type != 1">{{inspect_info.score * 1}}分</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <p class="xing-title">标<img style="transform:translateY(-1px)" :src="require('../assets/index/star.png')" alt="">通过项</p>
            </template>
            {{inspect_info.type == 1 ? inspect_info.examine_stars : inspect_info.stars}}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <el-divider></el-divider>
      <div class="part">
        <div class="title">改进建议</div>
        <p class="advice" v-for="(item,index) of opinion" :key="index">{{item.content}}</p>
        <p class="advice" v-if="!opinion.length">无</p>
      </div>
      <el-divider></el-divider>
      <div class="part">
        <div class="title">7项指标得分对比情况</div>
        <div id="chart"></div>
        <el-table size="small" :data="tableData" style="width: 100%">
          <el-table-column align="center" prop="name" label="指标">
          </el-table-column>
          <el-table-column align="center" label="分值">
            <template slot-scope="scope">
              {{scope.row.score * 1}}分
            </template>
          </el-table-column>
          <el-table-column align="center" label="自评" v-if="inspect_info.type == 1">
            <el-table-column align="center" label="得分">
              <template slot-scope="scope">
                {{scope.row.inspect_score * 1}}分
              </template>
            </el-table-column>
            <el-table-column align="center" label="比重">
              <template slot-scope="scope">
                {{(scope.row.inspect_ratio * 1000000 / 10000)}}%
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="评审" v-if="inspect_info.type != 1">
            <el-table-column align="center" label="得分">
              <template slot-scope="scope">
                {{scope.row.inspect_score * 1}}分
              </template>
            </el-table-column>
            <el-table-column align="center" label="比重">
              <template slot-scope="scope">
                {{(scope.row.inspect_ratio * 1000000 / 10000)}}%
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="评审" v-if="inspect_info.type == 1">
            <el-table-column align="center" label="得分">
              <template slot-scope="scope">
                <p v-if="scope.row.examine_results == 1" style="color:#F04134;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 2" style="color:#FFBA01;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 3" style="color:#1ABE6B;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 4" style="color:#0079FE;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else style="color:#0079FE"></p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="比重">
              <template slot-scope="scope">
                <p v-if="scope.row.examine_results == 1" style="color:#F04134;text-align: center;">{{(scope.row.examine_ratio * 1000000 / 10000)}}%</p>
                <p v-else-if="scope.row.examine_results == 2" style="color:#FFBA01;text-align: center;">{{(scope.row.examine_ratio * 1000000 / 10000)}}%</p>
                <p v-else-if="scope.row.examine_results == 3" style="color:#1ABE6B;text-align: center;">{{(scope.row.examine_ratio * 1000000 / 10000)}}%</p>
                <p v-else-if="scope.row.examine_results == 4" style="color:#0079FE;text-align: center;">{{(scope.row.examine_ratio * 1000000 / 10000)}}%</p>
                <p v-else style="color:#0079FE"></p>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="color">
          <div><span style="background:#F04134"></span>该指标需要重大改进</div>
          <div><span style="background:#FFBA01"></span>该指标接近国家标准</div>
          <div><span style="background:#1ABE6B"></span>该指标符合国家标准</div>
          <div><span style="background:#0079FE"></span>该指标超过国家标准</div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="part">
        <div class="title xing-title">标<img style="transform:translateY(-1px)" :src="require('../assets/index/star.png')" alt="">指标评审情况</div>
        <div class="progress" v-if="inspect_info.type == 1">
          <img src="../assets/charts/ziping.png" alt="">
          <div class="right">
            <div class="line">
              <span>通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.inspect.percentage0" color="#409eff"></el-progress>
              <span>{{stars_num.inspect.is_stars}}项</span>
            </div>
            <div class="line">
              <span>未通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.inspect.percentage1" color="#f56c6c"></el-progress>
              <span>{{stars_num.inspect.no_stars}}项</span>
            </div>
          </div>
        </div>
        <div class="progress" v-if="inspect_info.type == 1">
          <img src="../assets/charts/pingshen.png" alt="">
          <div class="right">
            <div class="line">
              <span>通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.examine.percentage0" color="#409eff"></el-progress>
              <span>{{stars_num.examine.is_stars}}项</span>
            </div>
            <div class="line">
              <span>未通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.examine.percentage1" color="#f56c6c"></el-progress>
              <span>{{stars_num.examine.no_stars}}项</span>
            </div>
          </div>
        </div>
        <div class="progress" v-if="inspect_info.type != 1">
          <img src="../assets/charts/pingshen.png" alt="">
          <div class="right">
            <div class="line">
              <span>通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.percentage0" color="#409eff"></el-progress>
              <span>{{stars_num.is_stars}}项</span>
            </div>
            <div class="line">
              <span>未通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.percentage1" color="#f56c6c"></el-progress>
              <span>{{stars_num.no_stars}}项</span>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="part" style="margin-bottom: 100px;">
        <div class="title xing-title">标<img style="transform:translateY(-1px)" :src="require('../assets/index/star.png')" alt="">指标没通过明细</div>
        <template v-for="(item,index) of stars_list">
          <div :key="index" class="item">
            <p class="name">{{item.object_pname}}</p>
            <p class="content" v-for="(item2,index2) of item.object_name" :key="index2">{{item2}}</p>
          </div>
        </template>
        <p class="advice" v-if="!stars_list">无</p>
      </div>
    </div>
  </div>
  <!-- <el-tabs class="tabs">
    <el-tab-pane label="评估报告">
    </el-tab-pane>
    <el-tab-pane label="评估详情">
    </el-tab-pane>
  </el-tabs> -->
  <img id="newimg" :src="imgSrc" style="width:100%;opacity:1;position:absolute;left:0;top:0;" />
  <!-- <el-footer id="footer">
    <span>ICP备案编号：京ICP备13043694号</span>
    <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964" target="_blank" style="text-decoration: none;color: #fff;cursor: pointer;"><img src="../assets/index/beian.png" style="float:left;margin-right:4px;"/>京公网安备 11010502049964号</a></span>
    <span>技术支持：京学集团</span>
  </el-footer> -->
</div>
</template>

<script>
import html2canvas from "html2canvas"
export default {
  data() {
    return {
      inspect_info: '',
      stars_list: [],
      opinion: '',
      stars_num: '',
      inspect_id: '',
      loading: false,
      imgSrc: '',
      chart: '',
      tableData: []
    }
  },
  mounted() {
    this.inspect_id = this.$route.query.inspect_id;
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/report/info",
        data: {
          inspect_id: this.inspect_id
        }
      });
      this.loading = false;
      if (res.code == 0) {
        this.inspect_info = res.data.inspect_info;
        this.opinion = res.data.opinion;
        this.stars_list = JSON.stringify(res.data.stars_list) == '[]' ? '' : res.data.stars_list;
        this.tableData = res.data.object_list;
        this.tableData.push({
          examine_ratio: (res.data.score_all.examine_score_all / res.data.score_all.score_all).toFixed(4),
          examine_score: res.data.score_all.examine_score_all + '',
          inspect_ratio: (res.data.score_all.inspect_score_all / res.data.score_all.score_all).toFixed(4),
          inspect_score: res.data.score_all.inspect_score_all,
          examine_results: this.inspect_info.results,
          name: "总分",
          score: res.data.score_all.score_all,
        })
        console.log(this.tableData)
        let a = res.data.stars_num;
        if (this.inspect_info.type == 1) {
          let c = a.inspect;
          if (c.is_stars + c.no_stars) {
            c.percentage0 = (c.is_stars / (c.is_stars + c.no_stars) * 100).toFixed(0) * 1;
            c.percentage1 = (c.no_stars / (c.is_stars + c.no_stars) * 100).toFixed(0) * 1;
          } else {
            c.percentage0 = 0;
            c.percentage1 = 0;
          }
          let d = a.examine;
          if (c.is_stars + c.no_stars) {
            d.percentage0 = (d.is_stars / (d.is_stars + d.no_stars) * 100).toFixed(0) * 1;
            d.percentage1 = (d.no_stars / (d.is_stars + d.no_stars) * 100).toFixed(0) * 1;
          } else {
            d.percentage0 = 0;
            d.percentage1 = 0;
          }
          this.stars_num = a;
        } else {
          if (a.is_stars + a.no_stars) {
            a.percentage0 = (a.is_stars / (a.is_stars + a.no_stars) * 100).toFixed(0) * 1;
            a.percentage1 = (a.no_stars / (a.is_stars + a.no_stars) * 100).toFixed(0) * 1;
          } else {
            a.percentage0 = 0;
            a.percentage1 = 0;
          }
          this.stars_num = a;
        }
        console.log(this.stars_num)
        this.chartInit();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    codeCanvas() {
      //整个图片转换完并赋值给图片容器
      html2canvas(this.$refs.codeRef).then(canvas => {
        const base64 = canvas.toDataURL('image/png')
        this.imgSrc = base64
      })
    },
    imgDownload() {
      // 下载
      const link = document.createElement('a');
      link.href = this.imgSrc;
      link.setAttribute('download', '报告')
      link.click();
    },
    chartInit() {
      let data = JSON.parse(JSON.stringify(this.tableData));
      data.pop();
      let indicator = data.map(e => {
        return {
          text: e.name,
          max: e.score * 1
        }
      });
      let value1 = data.map(e => {
        return e.inspect_score * 1
      });
      let value2 = data.map(e => {
        return e.examine_score * 1
      });
      let dataArr = [];
      if (this.inspect_info.type == 1) {
        dataArr = [{
            value: value1,
            name: '自评得分',
            symbol: 'rect',
            symbolSize: 12,
            lineStyle: {
              type: 'dashed'
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
              color: '#0083F5'
            }
          },
          {
            value: value2,
            name: '评审得分',
            symbolSize: 12,
            areaStyle: {
              color: new this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [{
                  color: 'rgba(255, 145, 124, 0.1)',
                  offset: 0
                },
                {
                  color: 'rgba(255, 145, 124, 0.9)',
                  offset: 1
                }
              ])
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
              color: '#F89400'
            }
          }
        ]
      } else {
        dataArr = [{
          value: value1,
          name: '评审得分',
          symbol: 'rect',
          symbolSize: 12,
          lineStyle: {
            type: 'dashed'
          },
          label: {
            show: true,
            formatter: function (params) {
              return params.value;
            }
          }
        }]
      }
      this.chart = this.$echarts.init(document.getElementById('chart'));
      let option = {
        color: ['#0083F5', '#F89400', '#56A3F1', '#FF917C'],
        legend: {
          position: 'top'
        },
        radar: [{

          },
          {
            indicator,
            center: ['50%', '50%'],
            radius: 120,
            axisName: {
              color: '#000',
            }
          }
        ],
        series: [{
          type: 'radar',
          radarIndex: 1,
          data: dataArr
        }]
      };
      option && this.chart.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  padding: 10px 20px;
  color: #000;

  img {
    width: 1200px;
  }

  .xing-title {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      margin: 0 2px;
    }
  }

  .report {
    // width: 960px;
    background: #fff;
    margin: 30px auto;
    overflow: auto;
    color: #000;

    .wrap {
      width: 650px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 20px auto;
    }

    h1 {
      font-size: 40px;
      font-weight: 500;
      color: #000000;
      line-height: 70px;
      margin-top: 20px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 46px;
      margin-bottom: 56px;
    }

    .part {
      width: 100%;

      .title {
        width: 100%;
        text-align: left;
        position: relative;
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;

        &::before {
          content: '';
          width: 5px;
          height: 24px;
          background: #0083F5;
          position: absolute;
          left: -15px;
          top: 0;
        }
      }

      .advice {
        font-size: 16px;
        color: #000;
        margin-bottom: 10px;
      }

      #chart {
        width: 500px;
        height: 400px;
        margin: 0 auto;
      }
    }

    .color {
      display: flex;
      margin: 26px 0 10px;
      font-size: 14px;
      color: #000;

      >div {
        display: flex;
        align-items: center;
        margin-right: 15px;

        span {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }

    .progress {
      display: flex;
      margin-bottom: 15px;

      img {
        width: 76px;
        height: 66px;
        margin-right: 25px;
        flex: 0 0 76px;
      }

      .right {
        height: 66px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        flex: auto;

        .line {
          display: flex;
          align-items: center;
          height: 26px;

          span {
            flex: 0 0 60px;
            text-align: left;
          }

          span:last-of-type {
            padding-left: 20px;
          }

          .pro {
            flex: auto;
          }
        }
      }
    }

    .item {
      .name {
        font-weight: bold;
      }

      p {
        font-size: 16px;
        margin-bottom: 6px;
        line-height: 1.8;
      }
    }
  }
}
</style>

<style lang="scss">
.page {
  .el-tabs__nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-tabs__item {
    padding: 14px 40px 0;
    font-size: 20px;
    height: 64px;
    line-height: 50px;
    color: #666666;
  }

  .el-tabs__item.is-active {
    color: #333;
    font-weight: bold;
  }

  .el-descriptions-item__container {
    font-size: 16px;
    color: #000;

    .el-descriptions-item__label {
      font-weight: bold;
    }
  }

  .des-two .el-descriptions-row {
    .el-descriptions-item:last-of-type {
      .el-descriptions-item__container {
        justify-content: flex-end;
      }
    }
  }

  .el-descriptions-item__container .el-descriptions-item__content {
    flex: 0 0 auto;
  }

  .el-table--small {
    font-size: 14px;
  }
}
</style>
